/* ----------------------------------------------------------------

	FOOTER

-----------------------------------------------------------------*/

footer {
    .main-footer {
        padding-top: 30px;
        padding-bottom: 50px;
        background-color: $white;
        &>.container {
            background-color: $main-color;    
            h3, p {
                color: $white;
                margin-bottom: 50px;
            }
            h3 {
                font-family: $font-sec-title;
                font-size: 38px;
            }
            p {
                font-size: 18px;
                text-align: center;
                @media screen and (max-width: $tablet) {
                    text-align: left;
                }
            }
            .brochure-img {
                padding: 0;
            }
            @media screen and (max-width: $tablet - 1) {
                padding-bottom: 26px;
            }
            @media screen and (max-width: 1024px) {
                h3, p {
                    margin-bottom: 40px;
                }
                img {
                    width: 100%;
                }
            }
            .footer-nav {
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                @media screen and (min-width: $breakpt) {
                    margin-top: 60px;
                }
                &>li {
                    font-family: $font-menu;
                    color: $color-3;
                    font-size: 1.5rem;
                    width: 50%;
                    margin-bottom: 35px;
                    @media screen and (min-width: $breakpt) {
                        width: auto;
                        font-size: 1.8rem;
                        margin-bottom: 0;
                        &:not(:last-child) {
                            margin-right: 50px;
                        }
                    }
                    @media screen and (min-width: $breakpt-lg) {
                        display: flex;
                        align-items: center!important;
                    }
                    &.socials-li {
                        width: 100%;
                        display: flex;
                        margin-bottom: 0;
                        align-items: flex-start;
                        @media screen and (min-width: $breakpt) {
                            flex-direction: column;
                            width: auto;
                            margin-left: 0;
                        }
                        @media screen and (min-width: $breakpt-lg) {
                            flex-direction: row;
                        }
                        .socials {
                            list-style: none;
                            display: flex;
                            padding-left: 15px;

                            @media screen and (min-width: $breakpt) {
                                padding: 0;
                                margin-top: 7px;
                                margin-left: 0;
                            }
                            @media screen and (min-width: $breakpt-lg) {
                                margin-left: 15px;
                            }
                            li {
                                &:not(:last-child) {
                                    margin-right: 10px;
                                }
                                a {
                                    width: 50px;
                                    height: 50px;
                                    border-radius: 50%;
                                    background-color: $color-3;
                                    color: $white;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    font-size: 2rem;
                                    @media screen and (min-width: $breakpt) {
                                        width: 45px;
                                        height: 45px;
                                        font-size: 2rem;
                                    }
                                }
                            }
                        }
                    }
                    &>a {
                        padding-right: 25px;
                    }
                }
            }
        }
    }
    .post-footer {
        background-color: $white;
        ul {
            list-style: none;
        }
        &>.container {
            padding-top: 20px;
            padding-bottom: 20px;
            background-color: $main-color;
            .mentions {
                list-style: none;
                padding: 0;
                margin: 0;
                @media screen and (min-width: $breakpt) {
                    display: flex;
                    flex-wrap: wrap;
                }
                div {
                    @media screen and (max-width: $tablet) {
                        border-bottom: solid 1px #bbbbc1;
                        margin: 0 -20px;
                    }
                }
                span {
                    font-weight: bold;
                    @media screen and (max-width: $tablet) {
                        padding-bottom: 15px;
                        //border-bottom: solid 1px #bbbbc1;
                        margin: 0 20px;
                        display: block;
                        
                        &.active:after {
                            content: "";
                            width: 25px;
                            height: 25px;
                            display: block;
                            background-image: url(../images/icons/down-grey.svg);
                            transform: rotate(180deg);
                            background-size: 100% 100%;
                            margin-left: 10px;
                            float: right;
                        }
                        
                        &:not(.active):after {
                            content: "";
                            width: 25px;
                            height: 25px;
                            display: block;
                            background-image: url(../images/icons/down-grey.svg);
                            background-size: 100% 100%;
                            margin-left: 10px;
                            float: right;
                        }
                    }
                }
                li {
                    font-family: $font-menu;
                    font-size: 1.6rem;
                    color: $white;
                    margin-right: 100px;
                    @media screen and (max-width: $tablet) {
                        margin-right: 0;
                        padding-bottom: 10px;
                        padding-top: 10px;
                    }
                    ul {
                        padding: 0;
                        @media screen and (min-width: $breakpt) {
                            margin-right: -5px;
                        }
                        @media screen and (max-width: $tablet){
                            display: none;
                        }
                        li {
                            margin-top: 15px;
                            @media screen and (max-width: $tablet) { 
                                //border-bottom: solid 1px #bbbbc1;
                            }
                        }
                    }
                    &:not(:last-child) {
                        margin-bottom: 5px;
                        @media screen and (min-width: $breakpt) {
                            margin-right: 0px;
                        }
                    }
                    
                    a {
                        font-family: $font-menu;
                        font-size: 1.6rem;
                        color: $white;
                    }
                    @media screen and (min-width: $breakpt) {
                        font-size: 1.6rem;
                        a {
                            font-size: 1.4rem;
                        }
                    }
                }
                .last-nav {
                    @media screen and (min-width: $breakpt-lg) {
                        margin-left: 45px;
                    }
                }
            }
        }
    }
    .copy-right {
        font-size: 13px;
        line-height: 30px;
        height: 30px;
        font-weight: 300;
        background-color: #395e97;
        border-top: solid 1px #6e92c8;
        text-align: right !important;
        color: $white;
    }
}