/* ----------------------------------------------------------------

	BUTTONS

-----------------------------------------------------------------*/

.btn-main {
    @include aspect-button-main();
}

.btn-2 {
    @include aspect-button-2();
}

.btn-container {
    margin-top: 40px;
    &>div {
        padding: 0 100px;
        display: flex;
        width: 100%;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        @media screen and (max-width: $breakpt-sm) {
            padding: 0 20px;
        }
        @media screen and (min-width: $breakpt) {
            padding: 0 30px;
        }
        a {
            &:not(:first-child) {
                margin-top: 15px;
            }
            border-color: $white;
            color: $white!important;
            @media screen and (min-width: $breakpt) {
                margin-top: 0!important;
                margin-left: 25px;
                margin-right: 25px;
            }
        }
    }
    &.btn-container-large {
        &>div {
            padding: 0 20px;
        }
    }
}

.btn-close-popup {
    @include aspect-button-close-popup();
}

.btn-add,
.btn-modify,
.btn-delete {
    font-family: $font-menu;
    font-size: 1.4rem;
    line-height: 1.4rem;
    color: $color-font!important;
    display: flex;
    align-items: center;
    @media screen and (min-width: $breakpt) {
        font-size: 1.8rem;
        line-height: 1.8rem;
    }
    span {
        width: calc(100% - 35px);
        @media screen and (min-width: $breakpt) {
            width: calc(100% - 45px);
        }
    }
}

.btn-add {
    &:before {
        content: "";
        width: 25px;
        height: 25px;
        display: block;
        background-image: url(../images/icons/btn-collapse-on.png);
        background-size: 100% 100%;
        margin-right: 10px;
        @media screen and (min-width: $breakpt) {
            width: 35px;
            height: 35px;
        }
    }
}

.btn-modify {
    &:before {
        content: "";
        width: 25px;
        height: 25px;
        display: block;
        background-image: url(../images/icons/btn-modify.png);
        background-size: 100% 100%;
        margin-right: 10px;
        @media screen and (min-width: $breakpt) {
            width: 35px;
            height: 35px;
        }
    }
}

.btn-delete {
    &:after {
        content: "";
        width: 25px;
        height: 25px;
        display: block;
        background-image: url(../images/icons/btn-collapse-off.png);
        background-size: 100% 100%;
        margin-left: 10px;
        @media screen and (min-width: $breakpt) {
            width: 35px;
            height: 35px;
        }
    }
}

.btn-doc {
    display: flex;
    align-items: center;
    &:before {
        content: "";
        width: 25px;
        height: 25px;
        display: block;
        background-image: url(../images/icons/btn-doc.png);
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center center;
        margin-right: 7px;
        @media screen and (min-width: $breakpt) {
            width: 35px;
            height: 35px;
        }
    }
}

.btn-mail {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    line-height: 1.4rem;
    @media screen and (min-width: $breakpt) {
        font-size: 1.6rem;
        line-height: 1.6rem;
    }
    &:before {
        content: "";
        width: 40px;
        height: 23px;
        display: block;
        background-image: url(../images/icons/icon-mail.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        margin-right: 10px;
        @media screen and (min-width: $breakpt) {
            width: 55px;
            height: 32px;
        }
    }
}


.call-us {
    display: flex;
    align-items: center;
    font-size: 1.4rem!important;
    @media screen and (min-width: $breakpt) {
        flex-direction: row;
        justify-content: center;
        font-size: 1.6rem!important;
    }
    &:before {
        content: "";
        background-image: url(../images/icons/icon-phone.png);
        width: 40px;
        height: 40px;
        margin-right: 10px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        display: block;
        @media screen and (min-width: $breakpt) {
            width: 60px;
            height: 60px;
        }
    }
}


.btn-need-help {
    display: inline-block;
    padding: 10px 20px;
    font-family: $font-menu;
    color: #6d6d6d;
    font-size: 1.8rem;
    background-color: $white;
    border: 2px solid #cdcdcd;
    border-radius: 10px;
    box-shadow: 0 7px 1px #e6e6e6;
}

.btn-rgpd {
  margin: 10px 20px;
  background-color: transparent;
  border-color: white;
  color: white!important;
//   background-image: -webkit-linear-gradient(top, $color-3-light, $color-3);
//   background-image: -moz-linear-gradient(top, $color-3-light, $color-3);
//   background-image: -ms-linear-gradient(top, $color-3-light, $color-3);
//   background-image: -o-linear-gradient(top, $color-3-light, $color-3);
//   background-image: linear-gradient(to bottom, $color-3-light, $color-3);
//   -webkit-box-shadow: 0 4px 1px $color-3-dark;
//   -moz-box-shadow: 0 4px 1px $color-3-dark;
//   -ms-box-shadow: 0 4px 1px $color-3-dark;
//   -o-box-shadow: 0 4px 1px $color-3-dark;
  &:hover {
    //   background-image: -webkit-linear-gradient(top, $color-3, $color-3-light);
    //   background-image: -moz-linear-gradient(top, $color-3, $color-3-light);
    //   background-image: -ms-linear-gradient(top, $color-3, $color-3-light);
    //   background-image: -o-linear-gradient(top, $color-3, $color-3-light);
    //   background-image: linear-gradient(to bottom, $color-3, $color-3-light);
  }
  @media screen and (max-width: $tablet - 1){
    height: 50px;
    width: 250px;
  }
  @media screen and (min-width: $breakpt) {
    margin: 5px;
    width: 150px;
    height: 45px;
    font-size: 1.6rem;
    line-height: normal;
  }
}
ul.roman-list{
    list-style-type: lower-alpha !important; 
    counter-reset: roman-counter !important; 
}
ul.roman-list li{
    counter-increment: roman-counter !important; 
    list-style-type: none; 
}

.roman-list li::before{
    background-image: none !important;
    content: counter(roman-counter, lower-roman) ". " !important;
}

ul.bullet-list{
    list-style-type: disc !important; 
}

.bullet-list li::before{
    background-image: none !important;
    margin-right: 0px !important;
}

@media screen and (min-width: 992px) {
    .bullet-list li::before {
        margin-right: 0px !important;
    }
}

ul.bullet-list-circle{
    list-style-type: circle !important; 
}

.bullet-list-circle li::before{
    background-image: none !important;
    margin-right: 0px !important;
}

@media screen and (min-width: 992px) {
    .bullet-list-circle li::before {
        margin-right: 0px !important;
    }
}