#homepage {
    //Banner home
    #banner-home {
        figure {
            background-color: $white;
        }
    }
    //End Banner home
    //Section About
    #about-tranquileo {
        img.medaillon {
            margin: 25px auto;
        }
        .intro {
            strong {
                font-family: $font-menu;
            }
            @media screen and (min-width: $breakpt) {
                font-size: 2rem;
                line-height: 2.6rem;
                margin-top: 30px;
            }
        }
        .container {
            @media screen and (min-width: $breakpt-lg) {
                padding: 30px 30px!important;
            }
        }
        .panel-default {
            @media screen and (max-width: 1024px) {
                margin-bottom: 100px;
            }
        }
        .panel-heading {
            background-color: $white;
            display: -webkit-box;
            justify-content: center;
            @media screen and (min-width: $breakpt-lg) {
                height: 70px;
            }
            h3 {
                a {
                    .panel-picto {
                        height: 30px;
                        margin-right: 20px;
                    }
                }
            }
        }
        .panel-body {
            text-align: center;
            @media screen and (max-width: $breakpt-lg - 1) {
                height: 110px;
            }
        }
        
        #about-accordion {
            margin-top: 30px;
            .panel-heading {
                .panel-picto {
                    height: 71px;
                    width: 71px;
                    margin: auto;
                    position: absolute;
                    left: 0;
                    top: -355px;
                    right: 0;
                    bottom: 0;
                    @media screen and (max-width: $breakpt-lg - 1) {
                        top: -240px;
                    }
                }
            }
            #about-accordion-row {
                margin: 0px 0px;
            }
            .row {
                @media screen and (min-width: $breakpt) {
                    display: flex;
                    flex-wrap: wrap;
                }
                // .panel {
                //     &:not(.col-md-4) {
                //         @media screen and (min-width: $breakpt) {
                //             width: 25%;
                //         }
                //     }
                //     .panel-collapse {
                //         .panel-body {
                //             padding-top: 0;
                //             padding-right: 30px;
                //             padding-left: 50px;
                //             @media screen and (min-width: $breakpt-lg) {
                //                 text-align: center;
                //             }
                //         }
                //     }
                // }
            }
            @media screen and (min-width: $breakpt) {
                margin-top: 50px;
                margin-left: -30px;
                margin-right: -30px;
                .row {
                    @media screen and (min-width: $breakpt-lg) {
                        display: flex;
                        flex-wrap: wrap;
                    }
                    //.panel {
                        
                        // .panel-collapse {
                        //     display: block!important;
                        //     height: auto!important;
                        //     width: 100%;
                        //     .panel-body {
                        //         padding-left: 0px;
                        //         padding-top: 0;
                        //         padding-right: 0px;
                        //         width: 100%;
                        //     }
                        // }
                    //}
                }
            }
        }
    }
    //End Section About
    //Section mise en place
    #mise-en-place {
        #carousel-mise-en-place {
            margin-top: 25px;
            .slick-list {
                .slick-slide {
                    text-align: center;
                    &.active {
                        .sep {
                            &:before,
                            &:after {
                                display: block!important;
                                @media screen and (min-width: $breakpt) {
                                    display: none!important;
                                }
                            }
                        }
                    }
                    .picto {
                        img {
                            height: 85px;
                            width: auto;
                            display: inline-block;
                            @media screen and (min-width: $breakpt) {
                                height: 60px;
                            }
                        }
                    }
                    .carousel-caption {
                        margin-top: 25px!important;
                        position: static;
                        padding: 0 25px;
                        @include size-text('mobile');
                        @media screen and (min-width: $breakpt) {
                            @include size-text('desktop');
                            margin-top: 15px!important;
                            padding: 20px 30px 0;
                            position: static;
                        }
                    }

                    .sep {
                        height: 10px;
                        width: 100%;
                        left: 0;
                        top: 160px;
                        margin-top: 30px;
                        transition: all .2s ease-in;
                        &:before,
                        &:after {
                            content: "";
                            display: none;
                            height: 100%;
                            width: 70px;
                            top: 0;
                            position: absolute;
                        }
                        &:before {
                            left: 0;
                        }
                        &:after {
                            right: 0;
                        }
                        @media screen and (min-width: $breakpt) {
                            position: static;
                            height: 10px;
                            margin-top: 35px;
                        }
                    }
                    &:nth-child(4n+1) {
                        .sep {
                            background-color: #f5f5f5!important;
                            @media screen and (min-width: $breakpt) {
                                border-top-left-radius: 5px;
                                border-bottom-left-radius: 5px;
                            }
                            &:before {
                                background-color: #aaaaaa;
                            }
                            &:after {
                                background-color: #d5d5d5;
                            }
                        }
                    }
                    &:nth-child(4n+2) {
                        .sep {
                            background-color: #d5d5d5;
                            &:before {
                                background-color: #f5f5f5;
                            }
                            &:after {
                                background-color: #bebebe;
                            }
                        }
                    }
                    &:nth-child(4n+3) {
                        .sep {
                            background-color: #bebebe;
                            &:before {
                                background-color: #d5d5d5;
                            }
                            &:after {
                                background-color: #aaaaaa;
                            }
                        }
                    }
                    &:nth-child(4n+4) {
                        .sep {
                            background-color: #aaaaaa;
                            &:before {
                                background-color: #bebebe;
                            }
                            &:after {
                                background-color: #f5f5f5;
                            }
                        }
                    }
                    &:last-child {
                        .sep {
                            @media screen and (min-width: $breakpt) {
                                border-top-right-radius: 5px;
                                border-bottom-right-radius: 5px;
                            }
                        }
                    }
                    @media screen and (max-width: $breakpt) {
                        &.slick-active {
                            .sep {
                                width: 80%;
                                margin-left: 10%;
                            }
                        }
                        &:not(.slick-active) {
                            .sep {
                                width: 110%;
                            }
                        }
                        &.slick-active + .slick-slide {
                            .sep {
                                margin-left: -10%;
                            }
                        }
                    }
                }

            }
            .slick-dots {
                margin-top: 35px;
            }
        }
        .carousel-control {
            .glyphicon-chevron-left,
            .glyphicon-chevron-right {
                top: 70px;
            }
            .glyphicon-chevron-left:before,
            .glyphicon-chevron-right:before {
                width: 18px;
                height: 35px;
                content: "";
                background-size: cover;
                display: block;
            }
        }
        .btn-container {
            margin-top: 50px;
            color: $white!important;
            border: 2px solid transparent;
            @media screen and (min-width: $breakpt) {
                margin-top: 75px;
            }
            #mise-en-place-btn-subscribe {
                background-color: $medium-lite-red;
                border: none;

            }
            .btn-2 {
                background-color: transparent;
                color: $white!important;
                border-color: $white;
            }
            a {
                font-size: 18px;
                font-weight: bold;
                color: $white!important;
            }
        }
    }
    //End Section mise en place
    //Section Témoignages
    #temoignages {
        .container {
            @media screen and (min-width: $breakpt) {
                padding-bottom: 0;
            }
            #carousel-temoignages {
                .carousel-inner {
                    @media screen and (min-width: $breakpt) {
                        display: flex;
                        overflow: visible;
                    }
                    .item-temoignage {
                        @media screen and (min-width: $breakpt) {
                            width: 25%;
                        }
                    }
                }
                .carousel-control {
                    .glyphicon-chevron-left,
                    .glyphicon-chevron-right {
                        top: 50px;
                    }
                }
                .carousel-indicators {
                    li {
                        border: 2px solid $color-3;
                        &.active {
                            background-color: $color-3;
                        }
                    }
                }
            }
            .note-container {
                margin-top: 60px;
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                .logo-ekomi {
                    margin-top: 10px;
                }
                .note {
                    color: #a1a1a1;
                    font-size: 1.8rem;
                    margin: 0;
                }
                .stars {
                    list-style: none;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    margin: 0;
                    padding: 0;
                    li {
                        margin: 0 7px;
                        color: #f9cb49;
                        font-size: 35px;
                    }
                }
                @media screen and (min-width: $breakpt) {
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    .logo-ekomi {
                        margin-top: 0;
                    }
                    .stars-container {
                        margin-left: 25px;
                        .stars {
                            justify-content: flex-start;
                        }
                    }
                }
            }
        }
    }
    //End Section Témoignages
    //Crédit Impôts
    #credit-impots {
        &>.container {
            padding-bottom: 0;
        }
        .credit-impot-container {
            padding: 0 20px;
            @media screen and (min-width: $breakpt) {
                display: flex;
                padding: 0 15px;
                .block-recap {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
            .block-calcul {
                padding: 30px 20px;
                font-family: $font-book-cd;
                font-weight: normal;
                font-style: normal;
                .reduc-before {
                    font-size: 4.9rem;
                    line-height: 5.4rem;
                    font-family: $font-DmCd;
                    font-weight: normal;
                    font-style: normal;
                    span {   
                        font-size: 2.8rem;
                        font-family: $font-book-cd;
                        font-weight: normal;
                        font-style: normal;
                    }
                }
                .reduc-after {
                    font-size: 1.8rem;
                    margin-top: 20px;
                    margin-bottom: 0;
                    span {
                        font-family: $font-DmCd;
                        font-weight: normal;
                        font-style: normal;
                        font-size: 2.8rem;
                    }
                }
                @media screen and (min-width: $breakpt) {
                    padding: 40px 20px 35px;
                    .reduc-before {
                        font-size: 5rem;
                        font-family: $font-DmCd;
                        font-weight: normal;
                        font-style: normal;
                        line-height: 4.4rem!important;
                        span {
                            font-size: 2rem;
                        }
                    }
                    .reduc-after {
                        font-size: 1.8rem;
                        margin-top: 40px;
                        span {
                            font-family: $font-DmCd;
                            font-weight: normal;
                            font-style: normal;
                            font-size: 2.8rem;
                        }
                    }
                }
            }
            .block-recap {
                padding-top: 60px;
                background-color: #EEEEEE;
                @media screen and (min-width: $breakpt) {
                    margin-top: 0;
                    padding-left: 40px;
                    padding-top: 0;
                }
                ul {
                    margin-top: 30px;
                    @media screen and (min-width: $breakpt) {
                        margin: 15px 0 0;
                    }
                }
            }
        }
        .btn-container {
            background-color: #f9f9f9;
            padding-bottom: 35px;
            margin-top: 0;
            padding-top: 50px;
            @media screen and (min-width: $breakpt) {
                background-color: transparent;
                padding-bottom: 0;
                margin-top: 75px;
                padding-top: 0;
            }
        }
    }
    //End Crédit Impôts
    &.landing {
        .whatIsTranquileo {
            @media screen and (min-width: $breakpt) {
                margin-top: 65px;
            }
        }
        #about-tranquileo {
            &>.container {
                padding-bottom: 0;
                #about-accordion {
                    margin-top: 100px;
                    .row .panel .panel-collapse .panel-body {
                        padding-right: 15px;
                    }
                }
            }
        }
        #subscribe-now {
            &>.container {
                &>.row {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }
}

