/* ----------------------------------------------------------------

	GLOBAL

-----------------------------------------------------------------*/
body {
  font-size: 62.5%; // 1rem = 10px
  background-color: $white;
  height: 100vh;
  @media screen and (max-width: 300px) {
    min-width: 280px;
  }
  min-width: 305px;
  font-family: $font-menu;
  color: $color-font;
  &.no-scroll {
    position: fixed;
    width: 100%;
  }
  * {
    outline: none!important;
  }
  .hidden-item {
    display: none!important;
    opacity: 0!important;
  }
  a {
    &:hover {
      color: inherit;
    }
  }
  strong {
    font-family: $font-sec-title;
  }
  small {
    color: inherit;
  }
  .hideMe {
    display: none!important;
  }
  .wrapper {
    font-size: 1.6rem;
    @media screen and (min-width: $tablet + 1) {
      padding-top: 70px;
    }
  }
  .chapo {
    font-family: $font-menu;
    font-size: 1.7rem;
    @media screen and (min-width: $breakpt) {
      font-size: 2rem;
    }
  }
  // Loader
  .loader {
    width: 0;
    position: relative;
    span,
    span:after {
      margin-left: 20px;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: inline-block;
      position: absolute;
    }
    span {
      font-size: 10px;
      position: relative;
      text-indent: -9999em;
      border-top: 5px solid rgba(50, 50, 50, 0.1);
      border-right: 5px solid rgba(50, 50, 50, 0.1);
      border-bottom: 5px solid rgba(50, 50, 50, 0.1);
      border-left: 5px solid $color-3-med-dark;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation: load8 1.1s infinite linear;
      animation: load8 1.1s infinite linear;
    }
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .mailToFriend {
    a {
      display: flex;
      align-items: center;
      img {
        width: 30px;
        margin-right: 10px;
        @media screen and (min-width: $breakpt) {
          width: 40px;
        }
      }
      p {
        font-family: $font-menu;
      }
    }
  }
  .gtk {
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    padding: 22px;
    @media screen and (min-width: $breakpt) {
      padding: 30px;
    }
    &>span {
      width: calc(100% - 30px);
      padding-left: 15px;
      @media screen and (min-width: $breakpt) {
        width: calc(100% - 50px);
        padding-left: 25px;
      }
      .light {
        color: #929292;
      }
    }
    &:before {
      content: "";
      width: 30px;
      height: 30px;
      background-size: 100% 100%;
      display: block;
      @media screen and (min-width: $breakpt) {
        width: 40px;
        height: 40px;
      }
    }
    &.gtk-question {
      &:before {
        background-image: url(../images/icons/question-mark-blue.png);
      }
    }
    &.gtk-info {
      &:before {
        background-image: url(../images/icons/info-blue.png);
      }
    }
  }
  .icon-gtk {
    content: "";
    display: block;
    width: 31px;
    height: 31px;
    background-color: $main-color;
    background-image: url('../images/icons/icon-gtk-open.png');
    background-repeat: no-repeat;
    background-size: auto 13px;
    background-position: center center;
    border-radius: 50%;
    -webkit-transition: all .2s ease-in;
    -moz-transition: all .2s ease-in;
    -ms-transition: all .2s ease-in;
    transition: all .2s ease-in;
    @media screen and (min-width: $breakpt) {
      margin-top: 10px;
    }
    &:not(.collapsed) {
      background-image: url('../images/icons/icon-gtk-close.png');
      border-radius: 0;
      height: calc(100% + 44px);
      background-position: center 12px;
      @media screen and (min-width: $breakpt) {
        margin-top: 0;
        height: calc(100% + 60px);
        background-position: center 19px;
      }
    }
  }
  .field-with-gtk {
    position: relative;
    width: 100%;
    input {
      width: calc(100% - 41px);
      position: relative;
      z-index: 2;
      @media screen and (min-width: $breakpt) {
        width: 100%;
      }
    }
    .gtk-form-container {
      position: absolute;
      z-index: 1;
      top: 10px;
      left: 0;
      width: 100%;
      @media screen and (min-width: $breakpt) {
        top: 0;
      }
      .icon-gtk {
        margin-left: 10px;
        position: absolute;
        right: 0;
        @media screen and (min-width: $breakpt) {
          right: -41px;
        }
      }
    }
    .gtk-form-txt {
      position: absolute;
      background-color: $main-color;
      top: 54px;
      z-index: 5;
      right: 0;
      @media screen and (min-width: $breakpt) {
        top: 60px;
        width: calc(100% + 41px);
        left: 0;
      }
      p {
        padding: 10px;
        line-height: 2rem;
        color: $white;
        font-size: 1.45rem!important;
      }
    }
  }
  .toKnow {
    background-color: #f9f9f9;
    padding: 30px 25px;
    .toKnow-title {
      font-family: $font-sec-title;
      color: #878787;
      font-size: 2rem;
      margin-bottom: 15px!important;
      @media screen and (min-width: $breakpt) {
        font-size: 2.8rem;
      }
    }
    .toKnow-txt {
      font-size: 1.4rem!important;
      @media screen and (min-width: $breakpt) {
        font-size: 1.8rem;
      }
    }
  }
  .txt-DmCd {
    font-family: $font-menu!important;
  }
  .txt-hvy {
    font-family: $font-menu!important;
  }
  .container {
    position: relative;
    background-color: $white;
    padding-left: 20px;
    padding-right: 20px;
    @media screen and (min-width: $breakpt) {
      padding-left: 15px;
      padding-right: 15px;
    }
    .img-responsive {
      width: 100%;
    }
    .row {
      //display: flex;
      //flex-wrap: wrap;
      margin-left: -20px;
      margin-right: -20px;
      @media screen and (min-width: $breakpt) {
        margin-left: -15px;
        margin-right: -15px;
      }
    }
    .col-xs-1,
    .col-sm-1,
    .col-md-1,
    .col-lg-1,
    .col-xs-2,
    .col-sm-2,
    .col-md-2,
    .col-lg-2,
    .col-xs-3,
    .col-sm-3,
    .col-md-3,
    .col-lg-3,
    .col-xs-4,
    .col-sm-4,
    .col-md-4,
    .col-lg-4,
    .col-xs-5,
    .col-sm-5,
    .col-md-5,
    .col-lg-5,
    .col-xs-6,
    .col-sm-6,
    .col-md-6,
    .col-lg-6,
    .col-xs-7,
    .col-sm-7,
    .col-md-7,
    .col-lg-7,
    .col-xs-8,
    .col-sm-8,
    .col-md-8,
    .col-lg-8,
    .col-xs-9,
    .col-sm-9,
    .col-md-9,
    .col-lg-9,
    .col-xs-10,
    .col-sm-10,
    .col-md-10,
    .col-lg-10,
    .col-xs-11,
    .col-sm-11,
    .col-md-11,
    .col-lg-11,
    .col-xs-12,
    .col-sm-12,
    .col-md-12,
    .col-lg-12 {
      padding-left: 20px;
      padding-right: 20px;
      @media screen and (min-width: $breakpt) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
  .wrapper {
    section.section {
      &>.container {
        padding-top: 25px;
        padding-bottom: 25px;
        @media screen and (min-width: $breakpt) {
          padding: 30px 50px;
        }
      }
      p {
        @include size-text('mobile');
        @media screen and (min-width: $breakpt) {
          @include size-text('desktop');
        }
      }
    }
  }
  a {
    color: inherit;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }
  //Banner-top
  .banner-top {
    &>.container {
      padding-left: 0;
      padding-right: 0;
      @media screen and (min-width: $breakpt) {
        padding-bottom: 50px;
      }
    }
  }
  //End Banner home
  // Bg
  .bg-main {
    background-color: $main-color;
    color: $white;
  }
  // Breacrumb
  .breadcrumb {
    display: none;
    margin-bottom: 0;
    @media screen and (min-width: $breakpt) {
      padding-left: 50px;
      padding-right: 50px;
      display: block;
    }
    ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      padding-top: 10px;
      font-family: $font-menu;
      color: $color-font-medium;
      font-size: 1.2rem;
      @media screen and (min-width: $breakpt) {
        font-size: 1.6rem;
      }
      li {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-right: 7px;
          &:after {
            content: "";
            display: block;
            background-color: $color-font-medium;
            width: 3px;
            height: 3px;
            border-radius: 50%;
            margin-left: 7px;
          }
        }
        a {
          &:hover {
            color: $color-3-med-dark;
          }
        }
      }
    }
  }
}

// Responsive
.eltSm,
.eltMobile,
.eltLg {
  display: none!important;
}

.eltSm {
  @media screen and (max-width: $breakpt-sm) {
    display: block!important;
  }
}

.eltMobile {
  @media screen and (min-width: $breakpt-sm + 1) and (max-width: $breakpt - 1) {
    display: block!important;
  }
}

.eltLg {
  @media screen and (min-width: $breakpt) {
    display: block!important;
  }
}

.hideOnMobile {
  display: none!important;
}

.showOnMobile {
  display: block!important;
}

.hideOnLgScreen {
  display: block!important;
}

.showOnLgScreen {
  display: none!important;
}

@media screen and (min-width: $breakpt) {
  .hideOnMobile {
    display: block!important;
  }
  .showOnMobile {
    display: none!important;
  }
}

@media screen and (min-width: $breakpt-lg) {
  .hideOnLgScreen {
    display: none!important;
  }
  .showOnLgScreen {
    display: block!important;
  }
}
.accept-yt-ccokie {
  position: absolute;
  top: 10%;
  left: 50%;
  font-weight: bold;
  color: black;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 767px) {
    top: 45%;
    z-index: 3;
  }
}
.yt-img-container {
  position: relative;
  text-align: center;
  color: white;
  &>img {
    width: 100%;
    height: auto;
  }
}
.hero-text {
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 33%;
  left: 5%;
  color: #fff;
  font-size: 24px;
}
.hero-text strong {
  font-family: $font-sec-title!important;
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: bold!important;
}
.small-title-panel {
  padding-bottom: 120px;
}
.small-title-panel strong{
  font-family: $font-sec-title!important;
  margin-bottom: 30px;
  font-size: 48px;
  word-spacing: 0.05em;
  letter-spacing: -0.05em;
  font-weight: bold!important;
}
// Title
h1.main-title {
  position: relative;
  z-index: 2;
  margin: -30px 0 0 0;
  padding: 0 20px;
  width: 100%;
  @media screen and (min-width: $breakpt) {
    max-width: 670px;
    position: absolute;
    bottom: 0;
    left: 50px;
    padding: 0;
  }
  .main-title-content {
    padding: 25px 45px;
    background-color: $color-3-med-dark;
    color: $white;
    width: 100%;
    display: block;
    text-align: center;
    line-height: 3rem;
    font-family: $font-menu;
    position: relative;
    p:last-of-type {
      font-size: 2rem;
    }
    &.quoted {
      &:before {
        content: url("../images/icons/quote-o.png");
        display: inline-block;
        position: absolute;
        left: 15px;
        top: 20px;
      }
      &:after {
        content: url("../images/icons/quote-c.png");
        margin-left: 15px;
        display: inline-block;
        position: absolute;
        right: 15px;
        bottom: 10px;
      }
    }
    @media screen and (min-width: $breakpt) {
      font-size: 3.6rem;
      line-height: 3.6rem;
      &.quoted {
        padding: 30px 50px;
        &:before {
          left: 15px;
        }
        &:after {
          position: static;
          right: 40px;
        }
      }
    }
  }
}

.section-title,
.section-title a {
  color: $color-dark-grey;
  margin-top: 0;
  font-size: 42px;
  margin-bottom: 20px;
  font-family: $font-sec-title;
  font-weight: bold;
  line-height: 54px;
  text-align: center;
  letter-spacing: -0.01em;
  background-color: transparent!important;
  @media screen and (min-width: $breakpt) {
    font-size: 48px;
    margin-bottom: 40px;
  }
  &:hover {
    color: $color-dark-grey;
  }
  &.light {
    color: $white;
  }
  &.text-center {
    padding: 0 20px;
  }
  &.section-title-thin {
    font-family: $font-sec-title;
  }
}

h4.small-title, h3.small-title {
  color: $color-font;
  font-size: 1.9rem;
  font-family: $font-menu;
  position: relative;
  @media screen and (min-width: $breakpt) {
    font-size: 2.6rem;
    font-weight: bold;
    letter-spacing: -0.05rem;
  }
}
h4.small-title {
  font-size: 1.5rem!important;
  @media screen and (min-width: $breakpt) {
    font-size: 2.2rem!important;
  }
}
h3.small-title-margin {
  //color: $color-3;
  color: $color-font;
  //color: #fff;
  font-size: 1.9rem;
  font-family: $font-menu;
  position: relative;
  @media screen and (min-width: $breakpt) {
    font-size: 2.6rem;
    //margin: 60px -100px 7px;
  }
}
.small-title-margin span {
  text-align: center;
  display: flex;
  margin: auto;
  color: $color-3-med-dark;
}
// Accordions
.panel-group {
  .panel {
    border: none;
    box-shadow: none;
    &+.panel {
      margin-top: 0;
      @media screen and (min-width: $breakpt) {
        margin-top: 0;
      }
    }
    .panel-heading {
      background-color: transparent;
      border: none;
      height: 130px;
      padding-left: 0;
      padding-right: 0;
      h3.small-title {
        a {
          display: flex;
          width: 100%;
          align-items: center;
          width: 100%;
          position: relative;
          padding-right: 45px;
          @media screen and (min-width: $breakpt) {
            padding-right: 0;
          }
          &:after {
            content: "";
            width: 25px;
            height: 25px;
            display: inline-block;
            background: url(../images/icons/btn-collapse-off.png) center center no-repeat;
            background-size: cover;
            margin-left: auto;
            position: absolute;
            top: 1px;
            right: 0;
            @media screen and (min-width: $breakpt) {
              background: none;
            }
          }
          &.collapsed {
            &:after {
              background: url(../images/icons/btn-collapse-on.png) center center no-repeat;
              background-size: cover;
              @media screen and (min-width: $breakpt) {
                background: none;
              }
            }
          }
        }
      }
    }
    .panel-collapse {
      border: none;
      .panel-body {
        border: none;
        @include size-text('mobile');
        @media screen and (min-width: $breakpt) {
          @include size-text('desktop');
        }
      }
    }
  }
}

// Carousels
.carousel {
  .carousel-inner {
    .item {
      .carousel-caption {
        @include size-text('mobile');
        @media screen and (min-width: $breakpt) {
          @include size-text('desktop');
        }
        position: static;
        text-shadow: none;
      }
    }
  }
  .carousel-control {
    background: transparent!important;
    background-image: none!important;
  }
  .carousel-indicators {
    position: static;
    margin: 10px auto;
    li {
      width: 20px;
      height: 20px;
      border: 2px solid $white;
      margin: 0 10px;
    }
  }
}

//Custom-toggle Tranquileo
.custom-toggle-container {
  border: 1px solid $color-3-med-dark;
  //border-radius: 5px;
  @media screen and (min-width: $breakpt) {
    border: 2px solid $color-3-med-dark;
  }
  &>.btn, &>.btn-primary {
    width: 100%;
    background-color: $white;
    color: $color-3-med-dark!important;
    font-size: 1.7rem;
    font-family: $font-menu;
    border: none;
    //border-radius: 5px;
    border-bottom: 1px solid $color-3-med-dark;
    @media screen and (min-width: $breakpt) {
      border-bottom: 2px solid $color-3-med-dark;
      font-size: 2.2rem;
    }
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      color: $color-3-med-dark;
      outline: none;
      border: none;
      border-bottom: 1px solid $color-3-med-dark;
      background-color: transparent;
      @media screen and (min-width: $breakpt) {
        border-bottom: 2px solid $color-3-med-dark;
      }
      span {
        color: $color-3-med-dark!important;
      }
    }
    &.collapsed {
      border: none!important;
      &>div {
        &:after {
          transform: rotate(180deg);
        }
      }

    }
    &>div {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      span {
        width: calc(100% - 25px);
        white-space: normal;
        text-align: left;
        @media screen and (min-width: $breakpt) {
          width: calc(100% - 35px);
        }
      }
      &:after {
        content: "";
        display: block;
        width: 12px;
        height: 7px;
        transition: all .2s ease-in;
        background-image: url(../images/icons/toggle-arrow-up-blue.png);
        background-size: cover;
        @media screen and (min-width: $breakpt) {
          width: 27px;
          height: 15px;
        }
      }
    }
  }
  .custom-toggle-body {
    padding: 20px;
    border-radius: 5px;
    .strate {
      padding-bottom: 20px;
      &:not(:first-child) {
        padding-top: 20px;
      }
      &:not(:last-child) {
        border-bottom: 1px solid $color-3-med-dark;
      }
      h3.small-title {
        margin-bottom: 25px;
        margin-top: 0;
        @media screen and (min-width: $breakpt) {
          font-size: 2rem;
        }
      }
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          &:not(:last-child) {
            margin-bottom: 15px;
          }
          display: flex;
          flex-wrap: wrap;
          .label {
            color: $color-font;
            font-family: $font-menu;
            padding: 0;
            font-weight: normal;
            width: 100%;
            text-align: left;
            white-space: normal;
            @include size-text('mobile');
            @media screen and (min-width: $breakpt) {
              @include size-text('desktop');
              width: 240px;
              text-align: left;
            }
          }
          p {
            margin: 0;
            @media screen and (min-width: $breakpt) {
              width: calc(100% - 240px);
            }
          }
        }
        &.noLabel {
          li {
            &:before {
              content: "• ";
              margin-right: 10px;
              font-size: 2.5rem;
              line-height: 2rem;
            }
            p {
                width: calc(100% - 40px);
            }
          }
        }
      }
    }
  }
}

// Custom Carousel
.custom-control {
  .slick-prev, .slick-next {
    top: 20%;
    z-index: 20;
  }
  .slick-prev:before, .slick-next:before {
    content: ""!important;
    width: 18px;
    height: 35px;
    background-size: cover;
    display: block;
    top: 0;
  }
  .slick-prev:before {
    position: absolute;
    right: 0;
    background-image: url(../images/icons/arrow-w-prev.png);
  }
  .slick-next:before {
    position: absolute;
    left: 0;
    background-image: url(../images/icons/arrow-w-next.png);
  }

  &.custom-control-1 {
    .slick-prev:before {
      background-image: url(../images/icons/arrow-main-prev-blue.png);
    }
    .slick-next:before {
      background-image: url(../images/icons/arrow-main-next-blue.png);
    }
  }
  &.custom-control-2 {
    .slick-prev:before {
      background-image: url(../images/icons/arrow-w-prev.png);
    }
    .slick-next:before {
      background-image: url(../images/icons/arrow-w-next.png);
    }
    .slick-dots li button:before {
      border-color: $white;
    }
    .slick-dots li.slick-active button:before {
      background-color: $white;
    }
  }
}


.slick-arrow {
  width: 30px;
  height: 55px;
  &.slick-prev {
    left: 0;
    &:before {
      content: url(../images/icons/arrow-main-prev-blue.png);
    ;
    }
  }
  &.slick-next {
    right: 0;
    &:before {
      content: url(../images/icons/arrow-main-next-blue.png);
    ;
    }
  }
}

.slick-dots {
  position: static;
  margin-top: 20px;
  li {
    margin: 0 10px;
    button {
      padding: 0;
      &:before {
        content: "";
        width: 20px;
        height: 20px;
        border: 2px solid $color-3-med-dark;
        display: block;
        border-radius: 50%;
        position: static;
        opacity: 1!important;
      }
    }
    &.slick-active {
      button {
        &:before {
          background-color: $color-3-med-dark;
        }
      }
    }
  }
}

// Témoignages
.item-temoignage {
  @media screen and (min-width: $breakpt) {
    display: flex!important;
    padding: 50px 15px 0;
  }
  .pic {
    width: 110px;
    height: 110px;
    background-size: cover;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    @media screen and (min-width: $breakpt) {
      margin: 0;
    }
  }
  .carousel-caption {
    color: $color-font;
    text-align: left;
    padding: 15px 20px;
    margin-top: 25px;
    font-size: 1.7rem;
    line-height: 2.3rem;
    position: static;
    text-shadow: none;
    @media screen and (min-width: $breakpt) {
      width: calc(100% - 110px);
      padding: 0 30px;
      margin-top: 0;
    }
    .temoignage-txt {
      margin: 0;
      position: relative;
      @media screen and (min-width: $breakpt) {
        &:before, &:after {
          display: inline-block;
        }
        &:before {
          content: url(../images/icons/quote-temoignage-o.png);
          top: -43px;
          left: -46px;
          position: absolute;
        }
        &:after {
          content: url(../images/icons/quote-temoignage-c.png);
          vertical-align: top;
          margin-left: 35px;
        }
      }
    }
    .author {
      margin-top: 15px;
      color: $color-3-med-dark;
      font-family: $font-menu;
      @include size-text('mobile');
      @media screen and (min-width: $breakpt) {
        @include size-text('desktop');
        margin-top: 20px;
      }
    }
  }
}

// Custom list
ul {
  &.custom-list-1 {
    list-style: none;
    padding: 0;
    li {
      margin-bottom: 20px!important;
      @include size-text('mobile');
      @media screen and (min-width: $breakpt) {
        @include size-text('desktop');
        margin-bottom: 10px;
      }
      &:before {
        content: "";
        width: 25px;
        height: 25px;
        display: inline-block;
        background-image: url(../images/icons/check-blue.svg);
        background-size: cover;
        margin-right: 15px;
        vertical-align: middle;
        @media screen and (min-width: $breakpt) {
          width: 30px;
          height: 30px;
          margin-right: 15px;
        }
      }
      &>div {
        display: inline-block;
        width: calc(100% - 80px);
        vertical-align: middle;
      }
    }
  }
}
.social-icon {
  float: left;
  padding: 0 10px 0 0;
  margin-right: 0px!important;
}
// ARTICLES LISTE
.item-article {
  &:hover {
    cursor: pointer;
  }
  &:not(:first-child) {
    margin-top: 35px;
    @media screen and (min-width: $breakpt-sm) {
      margin-top: 0;
    }
  }
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0;
  .article-pic {
    margin-bottom: 25px;
    width: 100%;
    // @media screen and (max-width: $breakpt-sm) {
    //   margin: 0;
    //   width: 100%;
    // }
    .img {
      width: 100%;
      height: 220px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      // @media screen and (min-width: $breakpt-sm) {
      //   width: 230px;
      //   height: 150px;
      // }
    }
    p {
      margin-bottom: 10px!important;
    }
  }
  .article0 {
    @media screen and (min-width: 1025px) {
      margin-left: 235px;
    }
  }
  .article1 {
    @media screen and (min-width: 1025px) {
      margin-left: -35px;
    }
  }
  .article-txt {
    .click-me {
      color: $medium-lite-red;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
    }
  //  @media screen and (min-width: $breakpt-sm) {
      //width: calc(100% - 235px);
      //padding: 0 30px 0 20px;
   // }
    h3.small-title {
      margin-bottom: 25px;
      margin-top: 0;
      font-weight: bold;
      @media screen and (min-width: $breakpt-lg) {
        height: 40px;
      }
      @media screen and (min-width: $breakpt-sm) {
        font-size: 2rem;
      }
    }
  }
}
// PAGE CONFIRMATION
#page-confirmation {
  .breadcrumb {
    background-color: transparent;
  }
  .section {
    &>.container {
      margin-bottom: 45px;
    }
  }
}

// PAGE CONTACT
#page-contact {
  @media screen and (min-width: $breakpt) {
    margin-bottom: 45px;
  }
  .breadcrumb {
    background-color: transparent;
  }
  .small-title {
    margin-top: 25px;
  }
  .mentions {
    font-size: 1.2rem;
    @media screen and (min-width: $breakpt) {
      font-size: 1.4rem;
    }
  }
  #form-contactus {
    input, textarea {
      width: 100%;
    }
  }
  .data-treatment {
    font-size: 1.2rem;
    text-align: left;
    margin-top: 15px;
    @media screen and (min-width: $breakpt) {
      font-size: 2rem;
      width: 80%;
      margin: auto;
    }
    a {
      font-family: $font-menu;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// SIMPLE PAGE
.simple-page {
  .banner-top {
    .main-title {
      @media screen and (min-width: $breakpt) {
        max-width: 550px;
      }
    }
  }
  .simple-block {
    margin-top: 25px;
    @media screen and (min-width: $breakpt) {
      margin-top: 35px;
    }
    ul {
      //list-style-image: url(../images/icons/check-blue.svg);
      list-style: none;
      padding: 0px 0 0 30px;
      margin: 0;
      @include size-text('mobile');
      @media screen and (min-width: $breakpt) {
        @include size-text('desktop');
        //list-style-image: url(../images/icons/check-blue.svg);
      }
      li {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
        &:before {
          content: "";
          width: 25px;
          height: 25px;
          display: inline-block;
          background-image: url(../images/icons/check-blue.svg);
          background-size: cover;
          margin-right: 15px;
          vertical-align: middle;
          @media screen and (min-width: $breakpt) {
            width: 30px;
            height: 30px;
            margin-right: 15px;
          }
        }
      }
    }
    a {
      font-family: $font-menu;
      font-weight: bold;
    }
    .testimony {
      @media screen and (min-width: $breakpt) {
        padding: 10px 50px 10px 150px;
        .testimony-txt {
          position: relative;
          line-height: 2.5rem;
          &:before {
            content: url("../images/icons/quote-temoignage-o-small.png");
            display: inline-block;
            position: absolute;
            left: -35px;
            top: -10px;
          }
          &:after {
            content: url("../images/icons/quote-temoignage-c-small.png");
            margin-left: 15px;
            display: inline-block;
            vertical-align: top;
          }
        }
      }
      .testimony-author {
        color: $color-3;
        font-family: $font-menu;
        font-size: 1.7rem;
        margin-top: 5px;
      }
    }
    .toKnow {
      margin-top: 25px;
      @media screen and (min-width: $breakpt) {
        margin-top: 45px;
      }
    }
  }
}

// PAGE ERROR
#page-error {
  @media screen and (min-width: $breakpt) {
    margin: 45px 0;
  }
  .error-head {
    padding-top: 25px;
  }
  .error-statut {
    font-family: $font-menu;
    color: $main-color;
    font-size: 10rem;
    line-height: 7rem;
    padding: 25px 0;
    @media screen and (min-width: $breakpt) {
      font-size: 13rem;
      line-height: 10rem;
      padding: 45px 0;
    }
    small {
      font-family: $font-menu;
      font-size: 1.8rem;
      line-height: 1.8rem;
      color: $color-font;
    }
  }
  .error-mentions {
    @media screen and (min-width: $breakpt) {
      width: 70%;
      margin: auto;
    }
    a {
      font-family: $font-menu;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
#more-rgpd {
  padding: 0 0;
}
#accept-rgpd, #more-rgpd {
  @media screen and (max-width: 320px) {
    margin: 2px;
  }
  color: $white!important;
  background-color: transparent;
  border-color: $white;
  text-transform: uppercase;
  font-weight: 600 !important;
  position: relative;
  font-size: 1.4rem;
  &:hover {
    color: $color-dark-grey!important;
  }
  @media screen and (min-width: $breakpt) {
    padding: 0 0!important;
  }
}
// COOKIE BAR
#cookiebar {
  display: block;
  background-color: $medium-dark-grey;
  position: relative;
  padding: 15px;
  position: fixed;
  z-index: 100;
  width: 100%;
  bottom: 0;
  left: 0;
  // @media screen and (min-width: $breakpt) {
  //   padding: 25px;
  // }
  &.hide-cookiebar {
    display: none;
    @media screen and (min-width: $breakpt) {
      display: none;
    }
  }
  .rgpd-container {
    background-color: inherit;
    @media screen and (min-width: $breakpt) {
      display: flex;
    }
    &.hide-cookiebar {
      display: none;
      @media screen and (min-width: $breakpt) {
        display: none;
      }
    }
    .rgpd-info {
      margin-bottom: 10px;
      width: 100%;
      @media screen and (min-width: $breakpt) {
        margin: 0 5px;
        width: 60%;
      }
      h3 {
        color: #fff;
        font-family: $font-menu;
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 24px;
        @media screen and (max-width: 320px) {
          font-size: 22px;
        }
      }
      p {
        font-family: $font-menu;
        color: #fff;
        font-size: 1.6rem;
        @media screen and (max-width: 320px) {
          font-size: 1.5rem;
        }
        text-align: left;
        margin: 0;
        @media screen and (min-width: $breakpt) {
          font-size: 1.5rem;
        }
      }
  }
  .rgpd-buttons {
    display: block;
    width: 70%;
    
    button {
      text-align: center;
      vertical-align: middle;
      //margin: 10px 0;
    }
    @media screen and (min-width: 411px) {
      margin: 45px auto;
      button {
        margin: 10px 0;
      }
    }
    @media screen and (min-width: $breakpt) {
      //display: flex;
      width: 40%;
      text-align: center;
      button {
        margin: 10px 15px;      
        width: 245px;
        height: 55px;
      }
    }
  }
    a {
      display: block;
      font-family: $font-menu;
      &:hover {
        text-decoration: underline;
      }
      @media screen and (min-width: $breakpt) {
        display: inline;
      }
    }
  }
  .rgpd-more {
    display: none;
    background-color: inherit;
    max-height: 55vh;
    overflow: auto;
    position: static;
    padding-top: 30px;
    @media screen and (min-width: $breakpt) {
      padding: 0;
    }
    &.show-more-cookiebar {
      display: block;
      @media screen and (min-width: $breakpt) {
        display: flex;
      }
    }
    .btn-close {
      width: 30px;
      height: 30px;
      background-color: $white;
      border-radius: 50%;
      position: absolute;
      top: 7px;
      right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:before {
        content: "";
        display: block;
        width: 12px;
        height: 14px;
        background-image: url(../images/icons/icon-gtk-close-main.png);
        background-size: cover;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &-section {
      font-family: $font-menu;
      color: #fff;
      @media screen and (min-width: $breakpt) {
        padding-right: 20px;
      }
      .rgpd-title-more {
        display: flex;
        .rgpd-checkbox-ctnr {
          margin: 20px 20px 10px 20px;
        }
      }
      .toggle-btn {
        position: relative;
        display: block;
        width: 40px;
        height: 20px;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        transform: translate3d(0,0,0);
        &:before {
          content: "";
          position: relative;
          top: 3px;
          left: 0px;
          width: 40px;
          height: 20px;
          display: block;
          background: transparent;
          border: 1px solid $medium-grey;
          border-radius: 15px;
          transition: background .2s ease;
        }
        span {
          position: absolute;
          top: 3px;
          left: 0px;
          width: 20px;
          height: 20px;
          display: block;
          background: $medium-grey;
          border-radius: 10px;
          box-shadow: 0 3px 5px rgba(#f9f9f9,.3);
          transition: all .2s ease;
        }
      }

      .rgpd-checkbox:checked + .toggle-btn {
        &:before {
          background: $color-rgpd;
        }
        span {
          background: #fff;
          transform: translateX(25px);
          transition: all .2s cubic-bezier(.8,.4,.3,1.25), background .15s ease;
          box-shadow: 0 3px 8px rgba($color-rgpd,.2);
        }
      }
      p {
        font-size: 1.6rem;
        // text-align: center;
        margin: 0 0 15px 0;
        @media screen and (min-width: $breakpt) {
          font-size: 1.5rem;
        }
        .rgpd-link {
          font-family: $font-menu;
          text-decoration: underline;
        }
      }
    }

  }
}
.text-center {
  text-align: center;
}
section {
  .container {
    .page-title {
      padding: 0 20px;
      font-size: 48px;
      margin-top: 60px;
      margin-bottom: 15px;
      font-family: $font-sec-title;
      font-weight: 700;
      line-height: 54px;
      text-align: center;
      letter-spacing: -.01em;
      @media screen and (max-width: $tablet - 1){
        font-size: 35px;
        line-height: 1.1;
      }
    }
  }
}
.btn-white-bg {
  background-color: $color-3-med-dark!important;
  color: $white!important;
  &:hover {
      background-color: #000072!important;
  }
}
#accept-rgpd-checkbox {
  @media screen and (max-width: 360px){
    margin: 0;
  }
  @media screen and (min-width: $breakpt){
    padding: 0 0!important;
    &:hover {
      color: #333!important;
    }
  }
}

