/* ----------------------------------------------------------------

	POPUPS

-----------------------------------------------------------------*/
// Scrollbar
@import "./includes/11_mCustomScrollbar";

.popup{
    position: fixed;
    background-color: rgba(0,0,0,.7);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    display: none;
    justify-content: center;
    align-items: center;
    &>.container {
        padding: 25px;
        margin: 0 25px;
        background: $white;
        max-height: calc(100% - 100px);
        overflow: hidden;
        @media screen and (min-width: $breakpt) {
            padding: 30px;
        }
        p {
            @include size-text('mobile');
            @media screen and (min-width: $breakpt) {
                @include size-text('desktop');
            }
        }
        .popup-head {
            position: absolute;
            width: calc(100% - 40px);
            display: flex;
            justify-content: flex-end;
            z-index: 20;
            @media screen and (min-width: $breakpt) {
                width: calc(100% - 60px);
            }
            .btn-close-popup-container {
                text-align: right;
            }
        }
        .popup-body {

            .logo-popup-container {
                margin-bottom: 15px;
                .logo-popup {
                    height: 40px;
                    @media screen and (min-width: $breakpt) {
                        height: 55px;
                    }
                }
            }
            .popup-block {
                padding: 25px 0;
                .section-title {
                    margin-bottom: 7px;
                }
                .infos {
                    margin-bottom: 25px;
                }
                .small-title {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

// POPUP SIMULATION
#popup-simulation {
    &>.container {
        .popup-body {
            .calcul-block {
                .popup-form {
                    @media screen and (min-width: $breakpt) {
                        display: flex;
                        .field-item {
                            width: calc(100% / 3);
                            padding-right: 75px;
                        }
                    }
                }
                .btn-container {
                    margin-top: 15px;
                }
            }
            .result-block {
                p {
                    text-align: center;
                }
                .result {
                    padding: 20px 0;    
                    font-family: $font-menu;
                    @media screen and (min-width: $breakpt) {
                        font-size: 2.2rem;
                        line-height: 3.2rem;
                    }
                    .amount-before-impot {
                        font-size: 2.2rem;
                        color: $main-color;
                        @media screen and (min-width: $breakpt) {
                            font-size: 3.4rem;
                        }
                    }
                    small {
                        font-family: $font-menu;
                        font-size: 1.3rem;
                        display: block;
                        @media screen and (min-width: $breakpt) {
                            font-size: 1.5rem;
                        }
                        .amount-after-impot {
                            font-size: 1.8rem;
                            font-family: $font-menu;
                            @media screen and (min-width: $breakpt) {
                                font-size: 2.6rem;
                            }
                        }
                    }
                }
                .deduc-fiscal {
                    font-size: 1.1rem;
                    line-height: 1.5rem;
                    margin-top: 25px;
                    @media screen and (min-width: $breakpt) {
                        font-size: 1.4rem;
                        line-height: 1.9rem;
                    }
                    a {
                        font-family: $font-menu;
                    }
                }
            }
        }
    }
}

// POPUP SOUSCRIPTION HELP
#popup-simulation-help {
    p {
        &:not(:last-child) {
            margin-bottom: 15px;
        }
        b {
            font-family: $font-menu;
        }
        a {
            font-family: $font-menu;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
// Custom Malibu scroll bar
.mCSB_draggerContainer {
    .mCSB_dragger {
        .mCSB_dragger_bar {
            background-color: $color-3;
        }
        &:hover,
        &.mCSB_dragger_onDrag {
            .mCSB_dragger_bar {
                background-color: $color-3;
            }
        }
    }
    .mCSB_draggerRail {
        width: 4px;
        background-color: #cacaca;
    }
}
