/* ----------------------------------------------------------------

	CUSTOM FORMS

-----------------------------------------------------------------*/

// CHECKBOX

/* Base for label styling */
.ui-autocomplete .ui-menu-item {
    font-size:1.8rem;
}
.field-item {
    &:not(:last-of-type) {
        margin-bottom: 20px;
    }
    &.tranquileo-text,
    &.tranquileo-email,
    &.tranquileo-password,
    &.tranquileo-tel,
    &.tranquileo-tel {}
    &.tranquileo-checkbox,
    &.tranquileo-radio {
        input,
        label {
            position: relative;
            min-height: 22px;
            @include size-text('mobile');
            @media screen and (min-width: $breakpt) {
                @include size-text('desktop');
                min-height: 30px;
            }
        }
        label {
            span {
                padding-left: 5px;
                display: block;
                @media screen and (min-width: $breakpt) {
                    padding-top: 5px;
                }
                small {
                    color: #949494;
                    display: block;
                    font-family: $font-MedCd;
                    font-weight: 500;
                    font-style: normal;
                }
            }
        }
        [type="checkbox"]:not(:checked),
        [type="checkbox"]:checked,
        [type="radio"]:not(:checked),
        [type="radio"]:checked {
            position: absolute;
            left: -9999px;
        }
        [type="checkbox"]:not(:checked)+label,
        [type="checkbox"]:checked+label,
        [type="radio"]:not(:checked)+label,
        [type="radio"]:checked+label {
            position: relative;
            padding-left: 1.95em;
            cursor: pointer;
        }
        /* aspect */
        [type="checkbox"]:not(:checked)+label:before,
        [type="checkbox"]:checked+label:before,
        [type="radio"]:not(:checked)+label:before,
        [type="radio"]:checked+label:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 22px;
            height: 22px;
            border: 1px solid #9b9b9b;
            background: #f6f6f6;
            border-radius: 1px;
            @media screen and (min-width: $breakpt) {
                width: 30px;
                height: 30px;
            }
        }
        /* checked mark aspect */
        [type="checkbox"]:not(:checked)+label:after,
        [type="checkbox"]:checked+label:after,
        [type="radio"]:not(:checked)+label:after,
        [type="radio"]:checked+label:after {
            content: url(../images/icons/check-blue.svg);
            position: absolute;
            top: 0px;
            left: 2px;
            line-height: 0.8;
            transition: all .2s;
            @media screen and (min-width: $breakpt) {
                content: url(../images/icons/check-blue.png);
                //left: 4px!important;
                //top: 0px!important;
                margin-left: -10px;
                margin-top: -9px;
            }
        }
        /* checked mark aspect changes */
        [type="radio"]:not(:checked)+label:after,
        [type="checkbox"]:not(:checked)+label:after {
            opacity: 0;
            transform: scale(0);
        }
        [type="radio"]:checked+label:after,
        [type="checkbox"]:checked+label:after {
            opacity: 1;
            transform: scale(1);
        }
        /* disabled */
        [type="checkbox"]:disabled:not(:checked)+label:before,
        [type="checkbox"]:disabled:checked+label:before,
        [type="radio"]:disabled:not(:checked)+label:before,
        [type="radio"]:disabled:checked+label:before {
            box-shadow: none;
            border-color: #bbb;
            background-color: #ddd;
        }
        [type="checkbox"]:disabled:checked+label:after,
        [type="radio"]:disabled:checked+label:after {
            color: #999;
        }
        [type="checkbox"]:disabled+label,
        [type="radio"]:disabled+label {
            color: #aaa;
        }
        /* hover style just for information */
        label:hover:before {
            border: 1px solid $color-3!important;
        }
    }
}

// POPUP FORM
#popup-simulation {
    .field-item {
        &.tranquileo-checkbox,
        &.tranquileo-radio {
            /* checked mark aspect changes */
            .unchecked+label:after {
                opacity: 0!important;
                transform: scale(0)!important;
            }
            [type="checkbox"]:not(.unchecked)+label:after,
            [type="radio"]:not(.unchecked)+label:after {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
}

// FORM SUBSCRIPTION
.tranquileo-form {
    padding-top: 25px;
    padding-bottom: 25px;
    .boolRadio {
        label {
            font-weight: 400!important;
            width: 150px;
            @media screen and (min-width: $breakpt) {
                width: auto;
                &:not(:last-child) {
                    margin-right: 50px;
                }
            }
        }
    }
    .field-item {
        @media screen and (min-width: $breakpt) {
            display: flex;
            align-items: flex-start;
        }
        &>label {
            font-weight: 600!important;
            margin-bottom: 5px;
            font-size: 1.4rem!important;
            padding-top: 15px;
            &.no-padding-top {
                padding-top: 0;
            }
            @media screen and (min-width: $breakpt) {
                font-size: 1.8rem!important;
                text-align: right;
                margin: 0;
            }
        }
        .form-control {
            border: none;
            box-shadow: none;
            background-color: #f4f3f3;
            border-radius: none;
            height: 50px;
            font-size: 16px;
            @media screen and (min-width: $breakpt) {
                padding: 0 15px;
                font-size: 1.8rem;
            }
            &:focus {
                outline: none;
                border: none;
                box-shadow: none;
            }
        }
        .birthdate {
            display: flex;
            .birthdate-field {
                width: calc(100% / 3);
                margin-bottom: 0;
                flex-direction: column;
                &:not(:last-child) {
                    margin-right: 10px;
                }
                input {
                    text-align: center;
                }
            }
        }
        &.field-textarea {
            .form-control {
                height: 160px;
                padding-top: 15px;
                padding-bottom: 15px;
            }
        }

        &.tranquileo-number {
             ::-webkit-input-placeholder {
                letter-spacing: 3px;
            }
             ::-moz-placeholder {
                letter-spacing: 3px;
            }
             ::-ms-input-placeholder {
                letter-spacing: 3px;
            }
             ::-moz-placeholder {
                letter-spacing: 3px;
            }
            input[type=number]::-webkit-outer-spin-button,
            input[type=number]::-webkit-inner-spin-button {
                -webkit-appearance: none;
                -moz-appearance: none;
                margin: 0;
            }
            input[type=number] {
                -webkit-appearance: textfield;
                -moz-appearance: textfield;
            }
        }
        &.tranquileo-radio {
            /* aspect */
            [type="radio"]:not(:checked)+label:before,
            [type="radio"]:checked+label:before {
                top: 3px;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                @media screen and (min-width: $breakpt) {
                    top: 0;
                    width: 21px;
                    height: 21px;
                }
            }
            /* checked mark aspect */
            [type="radio"]:not(:checked)+label:after,
            [type="radio"]:checked+label:after {
                content: "";
                position: absolute;
                top: 6px;
                left: 3px;
                width: 9px;
                height: 9px;
                border-radius: 50%;
                display: block;
                background-color: $color-3-med-dark;
                transition: all .2s;
                @media screen and (min-width: $breakpt) {
                    top: 13px;
                    left: 14px;
                    width: 13px;
                    height: 13px;
                }
            }
            /* hover style just for information */
            label:hover:before {
                border: 1px solid $main-color!important;
            }
        }

        &.tranquileo-select {
            .select-container {
                overflow: hidden;
                //background: #f4f3f3 url("../images/icons/toggle-arrow-down-blue.png") no-repeat 100% 50%;
                background-size: auto 100%;
                select::-ms-expand {
                    display: none;
                }
                select {
                    background-image: url(../images/icons/drop-down-arrow-blue.svg);
                    background-repeat: no-repeat;
                    background-position-x: 99%;
                    background-position-y: center;
                    border: none;
                    box-shadow: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    &:focus {
                        outline: none;
                    }
                }
            }
        }
        // Errors
        .error-field {
            border: 1px solid $color-error;
        }
        .errors-list {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                color: $color-error;
                font-size: 1.4rem;
                line-height: 1.6rem;
                &:first-child {
                    padding-top: 10px;
                }
            }
        }
        .errors-list-cgv {
            margin: 0;
            padding: 35px 0 0 55px;
            list-style: none;
            li {
                color: $color-error;
                font-size: 1.6rem;
                line-height: 2.6rem;
                &:first-child {
                    padding-top: 10px;
                }
            }
        }
        .errors-list-standingOrder {
            margin: 0;
            padding: 35px 0 0 55px;
            list-style: none;
            li {
                color: $color-error;
                font-size: 1.6rem;
                line-height: 2.6rem;
                &:first-child {
                    padding-top: 10px;
                }
            }
        }
        // End Errors
    }
    .adress {
        display: flex;
        flex-wrap: wrap;
        .adress-field {
            flex-direction: column;
            @media screen and (min-width: $breakpt) {
                margin: 0;
            }
            &.field-num {
                width: calc(30% - 10px);
                margin-right: 10px;
                @media screen and (min-width: $breakpt) {
                    width: 130px;
                }
                input {
                    text-align: center;
                }
            }
            &.field-type {
                width: 70%;
                @media screen and (min-width: $breakpt) {
                    width: 130px;
                    margin-right: 10px;
                }
            }
            &.field-name {
                @media screen and (min-width: $breakpt) {
                    width: calc(100% - 140px);
                    margin-right: 0;
                }
            }
        }
    }
    .address-more {
        display: flex;
        input {
            padding-right: 0;
            width: calc(100% - 41px);
            @media screen and (min-width: $breakpt) {
                width: 100%;
            }
        }
    }
    .ville-postal-container {
        @media screen and (min-width: $breakpt) {
            display: flex;
        }
        .row {
            @media screen and (min-width: $breakpt) {
                margin-left: 0!important;
                margin-right: 0!important;
            }
            &.field-postal {
                label {
                    @media screen and (min-width: $breakpt) {
                        width: 215px;
                    }
                    @media screen and (min-width: $breakpt-lg) {
                        width: 265px;
                    }
                }
                .postal {
                    width: 160px;
                    input {
                        text-align: center;
                    }
                }
            }
            &.field-city {
                @media screen and (min-width: $breakpt-lg) {
                    flex: 1;
                }
                .ville {
                    @media screen and (min-width: $breakpt) {
                        width: 380px;
                    }
                    @media screen and (min-width: $breakpt-lg) {
                        width: 420px;
                    }
                }
            }
        }
    }
}



