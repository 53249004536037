/* ----------------------------------------------------------------

	MIXINS

-----------------------------------------------------------------*/

// Button main
@mixin aspect-button-main() {
    font-family: $font-menu;
    width: 100%;
    font-size: 2rem;
    line-height: 2rem;
    display: inline-block;
    text-transform: uppercase;
    //background: $white;
    // background-image: -webkit-linear-gradient(top, $white, $light-grey);
    // background-image: -moz-linear-gradient(top, $white, $light-grey);
    // background-image: -ms-linear-gradient(top, $white, $light-grey);
    // background-image: -o-linear-gradient(top, $white, $light-grey);
    // background-image: linear-gradient(to bottom, $white, $light-grey);
    // -webkit-border-radius: 10;
    // -moz-border-radius: 10;
    // border-radius: 5px;
    color: $color-3-med-dark!important;
    padding: 15px 20px;
    // -webkit-box-shadow: 0 4px 1px $color-2-dark;
    // -moz-box-shadow: 0 4px 1px $color-2-dark;
    // -ms-box-shadow: 0 4px 1px $color-2-dark;
    // -o-box-shadow: 0 4px 1px $color-2-dark;
    margin-bottom: 4px;
    text-decoration: none;
    border: 2px solid;
    @media screen and (min-width: $breakpt) {
        width: 350px;
        font-size: 2.4rem;
        line-height: 2.4rem;
    }
    &:hover {
        // background-image: -webkit-linear-gradient(top, $light-grey, $white);
        // background-image: -moz-linear-gradient(top, $light-grey, $white);
        // background-image: -ms-linear-gradient(top, $light-grey, $white);
        // background-image: -o-linear-gradient(top, $light-grey, $white);
        // background-image: linear-gradient(to bottom, $light-grey, $white);
        text-decoration: none;
    }
}


// Button 2
@mixin aspect-button-2() {
    font-family: $font-menu;
    font-size: 2rem;
    line-height: 2rem;
    display: inline-block;
    -webkit-border-radius: 10;
    -moz-border-radius: 10;
    //border-radius: 5px;
    color: $color-3-med-dark!important;
    padding: 15px;
    text-decoration: none;
    border: 2px solid $color-3-med-dark;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    @media screen and (min-width: $breakpt) {
        font-size: 2.4rem;
        line-height: 2.4rem;
        width: 350px;
    }
}

// Size text-main
@mixin size-text($device) {
    margin: 0;
    font-weight: normal;
    @if $device=='mobile' {
        font-size: $size-text-mobile;
        line-height: $lh-text-mobile;
    }
    @else {
        font-size: $size-text-desktop;
        line-height: $lh-text-desktop;
    }
}

// Button close popup
@mixin aspect-button-close-popup() {
    font-family: $font-menu;
    font-size: 1.7rem!important;
    line-height: 1.7rem;
    display: inline-block;
    padding: 8px 22px 8px;
    //background: $color-3-light;
    //background-image: -webkit-linear-gradient(top, $color-3-light, $color-3);
    // background-image: -moz-linear-gradient(top, $color-3-light, $color-3-med);
    // background-image: -ms-linear-gradient(top, $color-3-light, $color-3-med);
    // background-image: -o-linear-gradient(top, $color-3-light, $color-3-med);
    // background-image: linear-gradient(to bottom, $color-3-light, $color-3-med);
    // -webkit-border-radius: 5px;
    // -moz-border-radius: 5px;
    //border-radius: 5px;
    //color: #ffffff!important;
    // -webkit-box-shadow: 0 5px 1px $color-3-dark;
    // -moz-box-shadow: 0 5px 1px $color-3-dark;
    // -ms-box-shadow: 0 5px 1px $color-3-dark;
    // -o-box-shadow: 0 5px 1px $color-3-dark;
    margin-bottom: 5px;
    text-decoration: none;
    @media screen and (min-width: $breakpt) {
        font-size: 1.8rem!important;
        line-height: 1.8rem;
        padding: 10px 40px 10px;
        // -webkit-box-shadow: 0 8px 1px $color-3-dark;
        // -moz-box-shadow: 0 8px 1px $color-3-dark;
        // -ms-box-shadow: 0 8px 1px $color-3-dark;
        // -o-box-shadow: 0 8px 1px $color-3-dark;
        margin-bottom: 8px;
    }
    //&:hover {
        //background: $color-3-light;
        //padding: 8px 22px 11px;
        // background-image: -webkit-linear-gradient(top, $color-3-med, $color-3-light);
        // background-image: -moz-linear-gradient(top, $color-3-med, $color-3-light);
        // background-image: -ms-linear-gradient(top, $color-3-med, $color-3-light);
        // background-image: -o-linear-gradient(top, $color-3-med, $color-3-light);
        // background-image: linear-gradient(to bottom, $color-3-med, $color-3-light);
        // color: #ffffff!important;
        // -webkit-box-shadow: 0 2px 1px $color-3-dark;
        // -moz-box-shadow: 0 2px 1px $color-3-dark;
        // -ms-box-shadow: 0 2px 1px $color-3-dark;
        // -o-box-shadow: 0 2px 1px $color-3-dark;
         //margin-bottom: 2px;
        //@media screen and (min-width: $breakpt) {
        //    padding: 10px 40px 15px;
            // -webkit-box-shadow: 0 3px 1px $color-3-dark;
            // -moz-box-shadow: 0 3px 1px $color-3-dark;
            // -ms-box-shadow: 0 3px 1px $color-3-dark;
            // -o-box-shadow: 0 3px 1px $color-3-dark;
        //     margin-bottom: 3px;
        //}
    //}
}
