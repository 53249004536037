/* ----------------------------------------------------------------

	VARIABLES

-----------------------------------------------------------------*/

//-------------------- Values --------------------//
$breakpt-sm: 540px;
$breakpt: 992px;
$breakpt-lg: 1200px;
$tablet: 768px;
//-------------------- Base Colors --------------------//
$main-color: #4976ba;
$bright-orange: #ec8e20;
$color-2: #8f23b3;
$color-2-light: #bc58dd;
$color-2-dark: #631483;
$color-3: #005499;
$color-3-med: #2b479a;
$color-3-light: #3a5ab1;
$color-3-med-dark: #00008f;
$color-3-dark: #1f326c;
$color-cyan-blue: #4976ba;
$color-font: #333333;
$color-font-medium: #424242;
$color-font-dark: #343434;
$light-grey: #f6f8fa;
$medium-grey: #e3e3e3;
$medium-dark-grey: #5f5f5f;
$white: #fff;
$black: #000;
$color-error: #f01d36;
$color-rgpd: #B3DA44;
$color-dark-grey: #333;
$medium-lite-red: #f07662;
$dark-red: #ec4d33;
//-------------------- Font sizes --------------------//
$size-text-mobile: 1.65rem;
$lh-text-mobile: 2.3rem;
$size-text-desktop: 1.8rem;
$lh-text-desktop: 2.2rem;
//-------------------- Font family --------------------//
$font-book: "Source Sans Pro", Arial, sans-serif;
$font-book-cd: "Source Sans Pro", Arial, sans-serif;
$font-hvy: "Source Sans Pro", Arial, sans-serif;
$font-MdCd: "Source Sans Pro", Arial, sans-serif;
$font-DmCd: "Source Sans Pro", Arial, sans-serif;
$font-Med: "Source Sans Pro", Arial, sans-serif;
$font-MedCd: "Source Sans Pro", Arial, sans-serif;
$font-Demi: "Source Sans Pro", Arial, sans-serif;
$font-menu: "Source Sans Pro", Arial, sans-serif;
$font-sec-title: 'Publico, Georgia, serif';
