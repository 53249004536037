/* ----------------------------------------------------------------

	HEADER

-----------------------------------------------------------------*/

header, .header {
    background-color: $white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    transition: top .2s ease-in;
    // Sticky header
    &.sticky-header-on {
        top: 0;
    }
    &.sticky-header-off {
        top: -115px;
    }
    &>.navbar {
        margin: 0;
        border-bottom-width: 1px;
        background: transparent;
        &>.container {
            background: transparent;
            padding: 0!important;
            height: 60px;
            display: flex;
            @media screen and (min-width: $breakpt-lg) {
                height: 110px;
            }
            &>.row {
                margin: 0;
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;
            }
            .header-left {
                height: 100%;
                #logo, #logo-sticky {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    padding: 0;
                    margin: 0;
                    padding-left: 10px;
                    padding-right: 10px;
                    @media screen and (min-width: $breakpt-lg) {
                        padding-left: 0;
                    }
                    img {
                        height: 35px;
                        @media screen and (min-width: $breakpt-lg) {
                            height: 60px;
                        }
                    }
                }
            } //End header-left
            .header-right {
                margin-left: auto;
                display: flex;
                align-items: center;
                height: 100%;
                @media screen and (min-width: $breakpt-lg) {
                    flex-direction: row-reverse;
                    padding-top: 30px;
                }
                #navbar, #navbar-sticky, #navbar-sticky-mobile {
                    padding-right: 0;
                    padding-left: 0;
                    position: absolute;
                    width: 101%;
                    top: 60px;
                    z-index: 11;
                    left: -1px;
                    background-color: $white;
                    border: none;
                    @media screen and (min-width: $breakpt) {
                        .hideOnMobile {
                          display: block!important;
                        }
                        .showOnMobile {
                          display: none!important;
                        }
                    }
                    @media screen and (min-width: $breakpt-lg) {
                        position: static;
                        width: auto;
                        margin-right: 50px;
                        background-color: transparent;
                    }
                    @media screen and (max-width: 1024px) {
                        height: calc(100vh - 60px)!important;
                    }
                    @media screen and (min-width: 768px) {
                        width: 370px;
                        right: 0;
                        left: auto;
                    }
                    @media screen and (min-width: 1024px) {
                        width: 500px;
                    }

                    .nav {
                        margin: 0;
                        li {
                            a {
                                font-family: $font-menu;
                                font-size: 2rem;
                                line-height: 2rem;
                                color: $color-3-med-dark;
                                &.btn-main {
                                    width: auto;
                                    font-size: 1.8rem!important;
                                    line-height: 1.8rem;
                                }
                                @media screen and (min-width: $breakpt-lg) {
                                    //font-size: 1.8rem;
                                    //line-height: 1.8rem;
                                    color: $color-3-med-dark;
                                    position: relative;
                                    z-index: 502;
                                    display: inline-block;
                                    height: 61px;
                                    line-height: 50px;
                                    font-size: 13px;
                                    text-transform: uppercase;
                                    padding-left: 15px;
                                    padding-right: 15px;
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                    &.navbar-collapse.collapse,
                    &.navbar-collapse.collapsing {
                        @media screen and (min-width: $breakpt-lg) {
                            display: flex!important;
                            align-items: flex-end;
                        }
                        .navbar-nav {
                            align-items: center;
                            margin: 0;
                            @media screen and (min-width: $breakpt-lg) {
                                display: flex;
                            }
                            &>li {
                                border-bottom: solid 1px #e5e5e5;
                                // &:last-child {
                                //     border: none;
                                // }
                                @media screen and (min-width: $breakpt-lg) {
                                    padding: 20px;
                                    border: none;
                                }
                                &:last-child {
                                    padding-right: 0;
                                }
                                &>a {
                                    padding: 20px 25px;
                                    position: relative;
                                    &:hover {
                                        color: $color-3-med-dark;
                                    }
                                    @media screen and (min-width: $breakpt-lg) {
                                        padding: 10px 0;
                                    }
                                    &:hover {
                                        color: $color-3-med-dark;
                                        background-color: $white;
                                        @media screen and (min-width: $breakpt-lg) {
                                            color: $color-3-med-dark;
                                            background-color: transparent;
                                            &:after {
                                                content: "";
                                                display: block;
                                                bottom: 0;
                                                background-color: $medium-lite-red;
                                                height: 4px;
                                                position: absolute;
                                                margin-bottom: -10px;
                                                width: 100%;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    &>a {
                                        background-color: $white;
                                        &:hover {
                                            color: $color-3-med-dark;
                                            background-color: $white;
                                            @media screen and (min-width: $breakpt-lg) {
                                                background-color: transparent;
                                                color: $color-3-med-dark;
                                            }
                                        }
                                        @media screen and (min-width: $breakpt-lg) {
                                            background-color: transparent;
                                            &:before {
                                                content: "";
                                                display: block;
                                                bottom: 0;
                                                background-color: $medium-lite-red;
                                                height: 4px;
                                                position: absolute;
                                                margin-bottom: -10px;
                                                width: 100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .navbar-toggle {
                    &#icon-burger, &#icon-burger-sticky {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 70px;
                        height: 100%;
                        position: relative;
                        // -webkit-transform: rotate(0deg);
                        // -moz-transform: rotate(0deg);
                        // -o-transform: rotate(0deg);
                        // transform: rotate(0deg);
                        // -webkit-transition: .5s ease-in-out;
                        // -moz-transition: .5s ease-in-out;
                        // -o-transition: .5s ease-in-out;
                        // transition: .5s ease-in-out;
                        cursor: pointer;
                        position: relative;
                        margin: 0;
                        padding: 0;
                        background-color: transparent;
                        background-image: none;
                        border: none;
                        border-radius: 0;
                        margin-left: 15px;
                        @media screen and (max-width: $breakpt-sm) {
                            width: 50px;
                            margin-left: 5px;
                        }
                        @media screen and (min-width: $breakpt-lg) {
                            display: none;
                            margin-left: 0;
                        }
                        &>div {
                            position: relative;
                            height: 35px;
                            width: 60px;
                        }
                        p {
                            position: absolute;
                            width: 100%;
                            text-align: center;
                            font-family: $font-menu;
                            font-size: 1.8rem;
                            top: 15px;
                            margin: 0;
                        }
                        img {
                            padding: 4px 0 10px 15px;
                        }
                        .burger-menu {
                            display: block;
                        }
                        .close-menu {
                            display: none;
                        }
                        &.open {
                            .close-menu {
                                display: block;
                            }
                            .burger-menu {
                                display: none;
                            }
                            //background-color: $main-color;
                            // p:before {
                            //     content: "FERMER";
                            //     color: $white;
                            //     font-size: 1.1rem;
                            // }
                            // span {
                            //     background-color: $white;
                            //     &:nth-child(1) {
                            //         top: 18px;
                            //         width: 0%;
                            //         left: 50%;
                            //     }
                            //     &:nth-child(2) {
                            //         -webkit-transform: rotate(45deg);
                            //         -moz-transform: rotate(45deg);
                            //         -o-transform: rotate(45deg);
                            //         transform: rotate(45deg);
                            //     }
                            //     &:nth-child(3) {
                            //         -webkit-transform: rotate(-45deg);
                            //         -moz-transform: rotate(-45deg);
                            //         -o-transform: rotate(-45deg);
                            //         transform: rotate(-45deg);
                            //     }
                            //     &:nth-child(4) {
                            //         top: 18px;
                            //         width: 0%;
                            //         left: 50%;
                            //     }
                            // }
                        }
                    }
                }
                .btn-header {
                    @media screen and (min-width: $breakpt-lg) {
                        margin-right: 0;
                        padding-top: 5px;
                    }
                    .btn-main {
                        font-family: $font-menu!important;
                        line-height: 1.5!important;
                        font-size: 13px!important;
                        width: auto!important;
                        padding: 6px 15px!important;
                        -webkit-box-shadow: 0 4px 1px $white;
                        -moz-box-shadow: 0 4px 1px $white;
                        -ms-box-shadow: 0 4px 1px $white;
                        -o-box-shadow: 0 4px 1px $white;
                        margin-bottom: 4px;

                        
                        -webkit-border-radius: 0!important;
                        -moz-border-radius: 0!important;
                        border-radius: 0px!important;

                        @media screen and (min-width: $breakpt-lg) {
                            text-transform: uppercase;
                            font-weight: 600 !important;
                            position: relative;
                            margin-bottom: 0px!important;
                        }
                    }
                }
            } //End header-right
        }
    }

}
header {
    @media screen and (max-width: $tablet) {
        position: relative;
    }
}
// Sticky menu
#sticky-menu{
    position: fixed;
    background: white;
    z-index: 100;
    top: 0;
    width: 100%;
    opacity: 0;
    transition: all .3s ease-out;
    top: -120px;
    &.shown {
        opacity: 1;
        top: 0;
    }
    .navbar-fixed-top {
        background-color: $white;
    }
    .navbar-nav>li>a {
        font-family: $font-menu!important;
        &.active {
            &:before {
                content: "";
                display: block;
                bottom: 0;
                background-color: $medium-lite-red;
                height: 4px;
                position: absolute;
                margin-bottom: -10px;
                width: 100%;
            }
        }
    }
}

// Change breakpoint when navbar becomes collapsed
@media (max-width: $breakpt-lg) {
    .navbar-header {
        float: none;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    }
    .navbar-collapse.collapse {
        display: none!important;
        &.in {
            &:not(.hideOnMobile) {
                display: block!important;
            }
        }
    }
    .navbar-nav {
        float: none!important;
        margin: 7.5px -15px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

